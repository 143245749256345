/*
 * @Author: wanxiaodong
 * @Date: 2020-01-03 16:55:05
 * @Last Modified by: wanxiaodong
 * @Last Modified time: 2024-08-27 10:19:17
 * @Description:
 */
import ContentType from '../../types/content'
const publicPath = 'https://static.xd-coder.com/'

export default {
    other: '其他什么玩意儿数据',
    content: [
        {
            title: '如需简历信息，请邮件联系我',
            type: ContentType.Mask,
            contentData: []
        },
        {
            title: '个人信息',
            subTitle: '',
            contentData: [
                {
                    type: ContentType.TextArray,
                    data: [
                        {
                            type: ContentType.Text,
                            data: '万孝东 | 男 | 1992',
                            span: 8,
                        },
                        {
                            type: ContentType.Text,
                            data: '本科 | 重庆理工大学 | 计算机科学与技术',
                            span: 8,
                        },
                        {
                            type: ContentType.Text,
                            data: '前端开发 | 8年+工作经验',
                            span: 8,
                        }
                    ]
                },
                {
                    type: ContentType.TextArray,
                    data: [
                        {
                            type: ContentType.Text,
                            data: '期望职位：Web高级前端工程师',
                            span: 6,
                        },
                        {
                            type: ContentType.Text,
                            data: '期望城市：重庆',
                            span: 6,
                        }
                    ]
                },
                {
                    type: ContentType.Text,
                    data: 'Github: <a href="https://github.com/wanxiaodong404/" target="_blank">https://github.com/wanxiaodong404/</a>',
                    html: true
                },
                {
                    type: ContentType.Text,
                    data: '网站: <a href="https://xd-coder.com/" target="_blank">https://xd-coder.com/</a>',
                    html: true
                }
            ]
        },
        {
            title: '联系方式',
            subTitle: '手机-邮箱-网址',
            contentData: [
                {
                    type: ContentType.TextArray,
                    data: [
                        {
                            type: ContentType.Text,
                            // data: '手机号：13452120141',
                            data: '手机号：134****0141',
                            span: 8,
                            attr: {
                                title: '13452120141'
                            },
                            dot: true
                        },
                        {
                            type: ContentType.Text,
                            data: '网址：<a href="https://xd-coder.com/" target="_blank">https://xd-coder.com/</>',
                            span: 8,
                            html: true
                        },
                        {
                            type: ContentType.Text,
                            data: '在线简历：<a href="https://cv.xd-coder.com/" target="_blank">https://cv.xd-coder.com/</>',
                            span: 8,
                            html: true
                        }
                    ]
                },
                {
                        type: ContentType.Text,
                        data: 'email：<a href="mailto://729779978@qq.com">729779978@qq.com</a> | <a href="mailto://xiaodonguncle.gmail.com">xiaodonguncle.gmail.com</a>',
                        html: true
                }
            ]
        },

        {
            title: '个人优势',
            subTitle: '',
            contentData: [
                {
                    type: ContentType.Text,
                    data: '7年+专职前端开发，对技术有热情，自我学习驱动力强；能够快速接受新的技术栈；',
                },
                {
                    type: ContentType.Text,
                    data: '沟通力强，能快速有效的和团队成员沟通制定需求，能很好地适应新的业务 需求，有一定的抗压能力',
                },
                {
                    type: ContentType.Text,
                    data: '热衷于计算机技术，对于系统、网络通信各方面都略有了解。兴趣偏技术（个人站点、NAS、Stable Diffusion、智能家居）',
                },
            ]
        },
        {
            title: '技能掌握',
            contentData: [
                {
                    type: ContentType.Text,
                    data: '前端框架： Vue2、Vue3、React'
                },
                {
                    type: ContentType.Text,
                    data: '微信小程序相关： Taro、微信小程序'
                },
                {
                    type: ContentType.Text,
                    data: 'Node.js： Express、Koa、Nodejs'
                },
                {
                    type: ContentType.Text,
                    data: 'Javascript：熟悉Es+新特性、熟练掌握并运用'
                },
                {
                    type: ContentType.Text,
                    data: 'Typescript： 熟悉并应用'
                },
                {
                    type: ContentType.Text,
                    data: '前端项目工程化：Webpack、Gulp、Vite、Less、Eslint、Babel....'
                },
                {
                    type: ContentType.Text,
                    data: '前端规范：husky + lint-staged + eslint + stylelint + commitlint + prettier + EditorConfig'
                },
                {
                    type: ContentType.Text,
                    data: '跨平台App：Electron'
                },
                {
                    type: ContentType.Text,
                    data: '后端相关了解：NodeJS、Python、JAVA、C++、PHP、MongoDB'
                },
                {
                    type: ContentType.Text,
                    data: '服务网络相关：Nginx、Https、网络通信、Docker'
                },
                {
                    type: ContentType.Text,
                    data: 'AI：了解 Stable Diffusion、Ollama'
                },
            ]
        },
        {
            title: '开源项目',
            contentData: [
                {
                    type: ContentType.Text,
                    data: '<a href="https://www.npmjs.com/package/picture2color" target="_blank">picture2color</a>',
                    html: true
                },
                {
                    type: ContentType.Text,
                    data: '.....',
                    html: false
                }
            ]
        },
        {
            title: '相关工作经历',
            contentData: [
                {
                    type: ContentType.Text,
                    data: '上海跨杰网络科技有限公司(2016-11~2018-04)'
                },
                {
                    type: ContentType.Text,
                    data: '-负责公司前端平台页面有序迭代',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-负责公司核心工具，互动小说编辑器开发（JQuery原生，集成脑图、富文本元素、二叉树）',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-负责公司核心工具，互动小说编辑器pro开发（Electron+React，集成脑图、富文本元素、多叉树）',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '猪八戒网络股份有限公司(2018-05~至今)'
                },
                {
                    type: ContentType.Text,
                    data: '-负责公司平台各端业务Web项目的迭代维护',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-负责公司平台公众号Web以及小程序项目迭代维护',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-公司部门Vue组件库项目搭建',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-负责八戒筋斗云低代码建站平台的迭代和维护(模版建站)',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-前端团队效率工具以及Node层插件开发(中间层插件，项目脚手架)',
                    dot: false
                },
            ]
        },
        {
            title: '项目经历',
            contentData: [
                {
                    type: ContentType.Text,
                    // data: '巧书平台<a href="https://qiaobooks.com/" target="_blank">https://qiaobooks.com/</a>',
                    data: '互动小说平台',
                    html: true
                },
                {
                    type: ContentType.Text,
                    data: '-时间：2016-11～2018-04',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-项目内容：互动小说移动pc自适应平台网站开发，需要持续迭代平台内容',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: `-职责：
                1、 负责对平台主站主要内容迭代、外投页面开发、推广h5小游戏开发、互动内容（文字、音频、视频、特效等）`,
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-技术栈详情：技术栈-Javascript+jQuery+Gulp+Sass；全程采用jQuery站撸，因为平台属于互动类平台，涉及到比较多的交互、动效，所以会涉及到video、audio、canvas、svg、逻辑脑图绘制等东西。以及h5小游戏。',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '互动小说平台在线（逻辑脑图+富文本）编辑器+阅读器，二叉树分支结构',
                    html: true
                },
                {
                    type: ContentType.Text,
                    data: '-时间：2016-11～2018-04',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-项目内容：和之前项目相互呼应，这个是平台的主要工具，需要一个编辑器和一个内容输出的逻辑阅读器',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: `-职责：
                1、逻辑编辑器：需要集富文本+脑图逻辑+实时编辑保存+数据备份+前进后退+预览+一键全文简图输出等功能的集合体；
                2、富文本编辑器：多个版本的富文本编辑器（原生command富文本、markdown解析编辑器、 draftjs衍生开发编辑器）
                3、阅读器：需要将存储的内容输出并根据用户交互进行逻辑阅读；
                4、可视化逻辑脑图：在内容编辑的基础上需要有可交互可视化的逻辑脑图结构编辑`,
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-技术栈详情：技术栈-Javascript+jQuery+Gulp+Sass；和之前的项目一样，采用全原生+jQuery撸出来的一个编辑器，手动实现富文本编辑功能，然后向后端输出JSON数据存储。',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '互动小说平台基于（Electron+React） 巧书编辑器Pro',
                    html: true
                },
                {
                    type: ContentType.Text,
                    data: '-时间：2017-10～2018-04',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-项目内容：在原有编辑阅读的基础上面添加更多功能的Pro版本编辑器',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: `-职责：
				1、基于第一个版本的所有功能
                2、在原来编辑器的基础上添加多分支+无限循环+游戏属性+更丰富富文本内容+跨平台app安装`,
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-技术栈详情：技术栈-Electron+React+Redux+Webpack；跨平台桌面App(Windows,MacOS)如上所述。',
                    dot: false
                },
                // {
                //     type: ContentType.Img,
                //     data: {
                //         url: `${publicPath}/mac.png`,
                //         alt: '巧书在线编辑器Pro'
                //     },
                //     style: {
                //         maxWidth: 500,
                //         width: '100%'
                //     }
                // },
                {
                    type: ContentType.Text,
                    data: '',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '猪八戒平台多端业务平台迭代维护',
                    html: true
                },
                {
                    type: ContentType.Text,
                    data: '-时间：2018-5～2019',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-项目内容：维护猪八戒平台移动、pc、后台等多个项目的业务迭代升级',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: `-职责：主要负责公司平台项目的优化、迭代工作，主要工作内容web前端内容迭代，对接java、dubbo接口实现node后端渲染向前台输出页面内容`,
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-技术栈详情：技术栈-Nodejs+Vue+Nuxt+Express+ServiceWorker+Dubbo等以及公司内部前端架构实现后端渲染一整套移动web端离线访问前端技术解决方案，并在此基础上进行可持续的迭代优化；解决内存泄漏、高并发延迟等问题。以及工具类插件支持。',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '猪八戒筋斗云低代码模版建站平台',
                    html: false
                },
                {
                    type: ContentType.Text,
                    data: '-时间：2019～2022',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-项目内容：迭代猪八戒筋斗云模版建站平台',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: `-职责：整体项目的维护
                1、平台功能升级
                2、项目结构优化`,
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-技术栈详情：Nuxt+Vue2+Vuex+NodeJS整体通过共用组件模式切换加上网站数据JSON化对数据进行保存和站点内容渲染。',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '猪八戒筋斗云前端业务基础组件库',
                    html: false
                },
                {
                    type: ContentType.Text,
                    data: '-时间：2019～2020',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-项目内容：迭代猪八戒筋斗云基础组件库项目迭代',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: `-职责：整体项目的维护升级`,
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-技术栈详情：Webpack+Vue2基础组件库并打包输出Npm组件包。',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '猪八戒公采平台小程序',
                    html: false
                },
                {
                    type: ContentType.Text,
                    data: '-时间：2023～2024',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-项目内容：搭建公采平台小程序业务平台',
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: `-职责：整体项目的技术选型、搭建、开发维护`,
                    dot: false
                },
                {
                    type: ContentType.Text,
                    data: '-技术栈详情：Taro+Vue3+Pinia+VantUI',
                    dot: false
                }
            ]
        },

    ]
}