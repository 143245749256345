/*
 * @Author: wanxiaodong
 * @Date: 2019-12-30 14:41:52
 * @Last Modified by: wanxiaodong
 * @Last Modified time: 2020-01-15 15:15:11
 * @Description:
 */
import React from 'react'
import {Route, BrowserRouter} from 'react-router-dom'
import IndexPage from './pages/index'
import TestPage from './pages/test'
const App = () => {
    return (
        <BrowserRouter>
            <Route path='/' exact component={IndexPage} />
            <Route path='/index' component={IndexPage}/>
            <Route path='/test' component={TestPage}/>
        </BrowserRouter>
    )
}
export default App