
/*
 * @Author: wanxiaodong
 * @Date: 2020-01-03 16:02:01
 * @Last Modified by: wanxiaodong
 * @Last Modified time: 2020-01-15 09:28:39
 * @Description:
 */
import React from 'react'
enum Content {Text, Img, TextArray, Mask}

export interface BlockInter {
    title: string,
    subTitle?: string,
    type?: undefined | Content,
    contentData: Array<BaseInter>
}

export interface BaseInter {
    type: number,
    className?: string
    dot?: boolean,
    data?: any,
    html?:boolean,
    style?: React.CSSProperties,
    attr?: object

}

export interface TextInter extends BaseInter {
    data: string,
    span?: number
}
export interface ImgInter extends BaseInter {
    data: object
}
export interface TextArrayInter extends BaseInter {
    data: Text
}

export default Content