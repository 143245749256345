/*
 * @Author: wanxiaodong
 * @Date: 2020-01-03 14:58:15
 * @Last Modified by: wanxiaodong
 * @Last Modified time: 2020-06-02 17:38:49
 * @Description:
 */
import React, {Component} from 'react'
import {Row, Col} from 'antd'
import ContentType, {TextInter, TextArrayInter, ImgInter, BaseInter, BlockInter} from '../../types/content'
interface Props{
    content: BlockInter,
    children?: any
}
export default class ContentBlock extends Component<Props>{
    constructor(props: Props) {
        super(props)
    }
    render() {
        let child = this.props.children;
        let className = 'content-item'
        if (!child) {
            child = (<React.Fragment>
                {this.props.content.contentData.map((item, index: number) => {
                    switch(item.type) {
                        case ContentType.Text: {
                            return <div className={className} key={index} {...item.attr}>
                                {item.html ? <p className={this.classNameRender(item)} dangerouslySetInnerHTML={{__html: item.data}} /> : <p className={this.classNameRender(item)}>{item.data}</p>}
                            </div>
                        }
                        case ContentType.Img: return <div className={className}  key={index} {...item.attr}>
                            {<img className={this.classNameRender(item)} style={item.style} src={item.data.url} alt={item.data.alt}/>}
                        </div>
                        case ContentType.TextArray: {
                            return <Row key={index} className={`${className} content-list-wrap`}>
                                {item.data.map((_item : TextInter, _index: number) => {
                                return <Col sm={{span: 24}} md={{span: _item.span}} className='content-list-item' key={index.toString() + _index}>
                                        {_item.html ? <p className={this.classNameRender(_item)} dangerouslySetInnerHTML={{__html: _item.data}} key={_index} {..._item.attr}/> : <p className={this.classNameRender(_item)} key={_index} {..._item.attr}>{_item.data}</p>}
                                    </Col>
                                })}
                            </Row>
                        }
                    }
                })}
            </React.Fragment>)
        }
        return (<div className="content-box">
            {child}
        </div>)
    }
    classNameRender(data: BaseInter, className?: string) {
        let classname = data.dot !== false ? 'item': ''; // 默认给到圆点 如果不需要给false值
        return classname + (data.className || '')
    }
}