/*
 * @Author: wanxiaodong
 * @Date: 2020-01-17 10:33:28
 * @Last Modified by: wanxiaodong
 * @Last Modified time: 2020-01-17 10:38:56
 * @Description:
 */
import React,{Component} from 'react'

export default class FooterInfo extends Component {
    constructor(props: any) {
        super(props)
    }
    render() {
        const date = new Date();
        return <div className="footer-info-container">
            @{date.getFullYear()}wanxiaodong <a href="https://beian.miit.gov.cn/" target='_blank'>渝ICP备18012609号</a>
        </div>
    }
}