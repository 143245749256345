/*
 * @Author: wanxiaodong
 * @Date: 2019-12-30 14:05:18
 * @Last Modified by: wanxiaodong
 * @Last Modified time: 2020-01-17 10:35:39
* @Description:
 */
import React, {Component} from 'react'
import {Layout} from 'antd'
import InfoBlock from '../components/info-block'
import data from '../asserts/config/data'
import {BlockInter} from '../types/content'
import FooterInfo from '../components/footer'
import WaterMark from '../components/watermark'

import '../asserts/style/pages/index.less'
const { Content, Footer, Sider, Header } = Layout


class IndexPage extends Component {
    state = data
    constructor(props: any) {
        super(props);
        this.printHandle = this.printHandle.bind(this)
    };
    render() {
        return <Layout>
            <Content>
                <div className="container">
                    <WaterMark />
                    {this.state.content.map((item:BlockInter, index: number) => {
                        return <InfoBlock blockInfo={item} key={index}/>
                    })}
                </div>
                <div className="print" onClick={this.printHandle}>Print</div>
            </Content>
            <Footer>
                <FooterInfo/>
            </Footer>
        </Layout>
    }
    printHandle() {
        window.print()
    }
}
export default IndexPage