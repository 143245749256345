import React, {Component, createRef, useRef, useState, useEffect} from 'react'
import {useParams, useLocation, withRouter, RouteComponentProps} from 'react-router-dom'
import{CSSTransition, SwitchTransition} from 'react-transition-group'
import ContentType, {BlockInter} from '../types/content'
import WaterMark from './watermark'


interface CustomProps{
    blockInfo: BlockInter,
    children?: any,
}

interface Props extends RouteComponentProps, CustomProps{
    blockInfo: BlockInter,
    children?: any,
}
class MailMask extends Component<Props> {
    state: {
        isShow: boolean,
        isBind: Boolean
    }
    nodeRef: any
    constructor(props: Props) {
        super(props);
        this.state = {
            isShow: props.location.search === '?a=1' ? false : true,
            isBind: false
        }
        this.nodeRef = createRef();
    }
    componentDidUpdate(preProps: Object, preState: {isShow: Boolean}) {
        if (this.state.isShow && this.state.isBind === false) {
            this.setState({isBind: true})
            this.nodeRef.current.addEventListener('mousewheel', this.stopWheel, {
                passive: false
            })
        }
            console.log(preState.isShow)
    }
    componentDidMount(): void {
        console.log(this.nodeRef)
        if (!this.nodeRef || !this.nodeRef.current) return
        this.setState({isBind: true})
        this.nodeRef.current.addEventListener('mousewheel', this.stopWheel, {
            passive: false
        })
    }
    componentWillUnmount(): void {

    }
    stopWheel(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        return false
    }
    render() {
        const {blockInfo} = this.props
        const isShow = this.state.isShow;
        const nodeRef = this.nodeRef;
        return <div>
            {/* <button style={{position: "relative", zIndex: 1000}} onClick={(e) => this.setState({isShow: !isShow})}>{isShow ? '关闭' : '开启'}</button> */}
            <CSSTransition
                classNames='fade'
                in={isShow}
                nodeRef={nodeRef}
                timeout={300}
                addEndListener={(done:() => void) => {
                    console.log(nodeRef.current);
                    if (!nodeRef || !nodeRef.current) return
                    (nodeRef.current as unknown as HTMLElement).addEventListener('transitionend', done, false)
                }}
                unmountOnExit
            >
                <div className="mail-mask__container" ref={nodeRef}>
                    <div className="mask"></div>
                    <div className="mask-body">
                        <div className="inner-body">
                            <div className="mask-body__title">{blockInfo.title}</div>
                            <div className="mask-body__content">
                                <a href="mailto:729779978@qq.com?subject=我想要一份简历&body=我是xx公司HR,我想要一份你的简历,请回复" className="button">发送邮件到729779978@qq.com获取简历</a>
                            </div>
                            {/* <div className="mask-body__close" onClick={e => this.setState({isShow: false})}>x</div> */}
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    }
}

export default withRouter(MailMask)