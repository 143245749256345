/*
 * @Author: wanxiaodong
 * @Date: 2019-12-30 14:05:18
 * @Last Modified by: wanxiaodong
 * @Last Modified time: 2019-12-30 14:47:46
* @Description:
 */
import React, {Component} from 'react'
import {Button} from 'antd'
class IndexPage extends Component {
    render() {
        return <h1>测试<Button type='primary'>按钮</Button></h1>
    }
}
export default IndexPage