/*
 * @Author: wanxiaodong
 * @Date: 2020-01-14 17:15:18
 * @Last Modified by: wanxiaodong
 * @Last Modified time: 2020-01-14 17:59:15
 * @Description:
 */
import React, {Component} from 'react'

export default class WaterMark extends Component {
    constructor(props: any) {
        super(props)
    }
    render() {
        return <div className='water-mark'>
            {Array(3).fill(1).map((value, index) => <h1 key={index}>by wanxiaodong</h1>)}
        </div>
    }
}