/*
 * @Author: wanxiaodong
 * @Date: 2019-12-31 17:30:12
 * @Last Modified by: wanxiaodong
 * @Last Modified time: 2020-01-03 17:36:01
 * @Description:
 */
import React, {Component} from 'react'
import ContentBlock from './content-block/index'
import MailMask from './mail-mask'
import ContentType, {TextInter, TextArrayInter, ImgInter, BaseInter, BlockInter} from '../types/content'
interface Props{
    blockInfo: BlockInter,
    children?: any
}
export default class extends Component <Props> {
    constructor(params : Props) {
        super(params);
    };
    render() {
        if (this.props.blockInfo.type === ContentType.Mask) {
            return <MailMask blockInfo={this.props.blockInfo}></MailMask>
        }
        return (<div className='info-block-container'>
            <div>
                <h2 className='title'>{this.props.blockInfo.title} <span className='sub-title'>{this.props.blockInfo.subTitle}</span></h2>
            </div>
            <div>
                <ContentBlock content={this.props.blockInfo}/>
            </div>
        </div>)
    }
}